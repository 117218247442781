import React from "react";
import {
  FormProvider,
  useForm,
  useFormState,
  useValidate,
} from "recalculate-form";

import referralCodeAPI from "api/referralCode";

import Input from "basics/input";

import { Modal, openAlert, useDialog } from "components/dialog";

import Button from "basics/button";

import globalClasses from "styles/classes.module.scss";

import { FormValues } from "./duck/types";

import classes from "./styles/classes.module.scss";

const ReferralCodeModal = () => {
  const form = useForm<FormValues>({
    defaultValues: { referralCode: "" },
  });
  const { isSubmitting } = useFormState(form);
  const { resolve } = useDialog();

  const submitHandler = async ({
    values: { referralCode },
  }: {
    values: FormValues;
  }) => {
    form.resetErrors();

    try {
      await referralCodeAPI.redeemReferralCode(referralCode);
      resolve(null);
      openAlert({
        message: `Referral code was successfully submitted`,
        type: "success",
      });
    } catch {
      openAlert({
        message: "An error occurred while submitting the referral code",
      });
    }
  };

  useValidate(() => null, [], form);

  return (
    <Modal>
      <Modal.Header classNames={{ title: globalClasses.gradientTitle }}>
        Redeem referral code
      </Modal.Header>
      <h3 className={classes.subtitle}>Get extra points</h3>
      <FormProvider form={form}>
        <form
          className={classes.form}
          onSubmit={form.handleSubmit(submitHandler)}
        >
          <Input
            size="md"
            className={classes.input}
            name="referralCode"
            placeholder="Referral code"
          />
          <Button
            disabled={isSubmitting}
            isLoading={isSubmitting}
            className={classes.submitButton}
            themeName="primary"
            type="submit"
            isBlock
          >
            Submit
          </Button>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default ReferralCodeModal;
